export const environment = {
  production: false,
  streamPlayPath: 'https://staging.api.streamplay.streamamg.com/fixtures/basketball/p/',
  playlistPageSize: 25,
  generalContentPageSize: 25,
  fixtureContentPageSize: 12,

  gtm: null,
  formSpree: 'https://formspree.io/mdoeerkb',
  inPlayer: {
    api: 'https://services.inplayer.com', // 'https://staging-v2.inplayer.com',
    merchant_uuid: '77dbe436-bbb0-48cf-977b-cc418a6f798d', // 'a993833e-95f1-465c-bfe0-34a7f4289c5d',
    environment: 'develop',
    assetsItemPath: '/items',
    initialPaywallAsset: 82480 //53957
  },

  wordpress: {
    baseUrl: 'https://wp-devfiba.streamamg.com/wp-json/wpa/v1',
    pages: {
      home: '/section?page=home-page',
      tv247: '/section?slug=24-7-tv-page',
      embed: '/section?page=embed-page',
      competitions: '/section?page=competitions-page',
      schedules: '/section?page=schedules-page',
      schedulesList: '/section?page=schedules-list-page',
      archive: '/section?page=archive-page',
      boxsets: '/section?page=boxsets-page',
      packages: '/section?page=packages-page',
      marketing: '/section?page=marketing-page',
      faq: '/section?page=faq-page',
      video: '/section?page=video-page',
      terms: '/section?page=terms-of-use-page',
      privacy: '/section?page=privacy-policy-page',
      company: '/section?page=company-details-page',
    },
    endpoints: {
      common: '/common_field',
      boxset: '/boxset',
      competition: '/competition',
    }
  }
};
