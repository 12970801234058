import { DOCUMENT } from '@angular/common';
import { Inject, isDevMode, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScriptTagState } from './script-tag-state.type';

export class ScriptTagService {
  private scripts: { [url: string]: BehaviorSubject<ScriptTagState> };
  private renderer: Renderer2;

  private static isInternetExplorer(script: HTMLScriptElement): boolean {
    return !!(script as any).readyState;
  }

  private static generateScriptTagState(isLoaded: boolean, isError: boolean): ScriptTagState {
    return { isLoaded, isError };
  }

  constructor(@Inject(DOCUMENT) private document: Document,
              private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.scripts = {};
  }

  public loadScript(url: string): Observable<ScriptTagState> {
    if (!this.scripts[url]) {
      if (!this.isScriptLoadedInTheDom(url)) {
        this.scripts[url] = new BehaviorSubject<ScriptTagState>(ScriptTagService.generateScriptTagState(false, false));

        const script: HTMLScriptElement = this.document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', url);

        if (ScriptTagService.isInternetExplorer(script)) {
          this.setUpInternetExplorerOnLoad(script, this.scripts[url]);
        } else {
          this.setUpOtherBrowsersOnLoad(script, this.scripts[url]);
        }

        this.setUpOnError(script, this.scripts[url]);

        this.renderer.appendChild(this.document.head, script);
      } else {
        // Script is already loaded, send true to the listener.
        this.scripts[url] = new BehaviorSubject<ScriptTagState>(ScriptTagService.generateScriptTagState(true, false));
      }
    }

    return this.scripts[url].asObservable();
  }

  private setUpInternetExplorerOnLoad(script: any, subject: BehaviorSubject<ScriptTagState>): void {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = () => {};
        subject.next(ScriptTagService.generateScriptTagState(true, false));
      }
    };
  }

  private setUpOtherBrowsersOnLoad(script: HTMLScriptElement, subject: BehaviorSubject<ScriptTagState>): void {
    script.onload = () => {
      subject.next(ScriptTagService.generateScriptTagState(true, false));
    };
  }

  private setUpOnError(script: HTMLScriptElement, subject: BehaviorSubject<ScriptTagState>): void {
    script.onerror = (error) => {
      subject.next(ScriptTagService.generateScriptTagState(false, true));

      if (isDevMode()) {
        console.log(error);
      }
    };
  }

  private isScriptLoadedInTheDom(url: string): boolean {
    return !!this.document.querySelector(`script[src="${url}"]`);
  }
}
