import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  {path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
  {path: 'embed', loadChildren: () => import('./pages/embed/embed.module').then(m => m.EmbedModule)},
  {path: 'archive', loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule)},
  {path: 'browse', loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowseModule)},
  {path: 'boxsets', loadChildren: () => import('./pages/simple-grid/simple-grid.module').then(m => m.SimpleGridModule)},
  {path: 'competitions', loadChildren: () => import('./pages/simple-grid/simple-grid.module').then(m => m.SimpleGridModule)},
  {path: 'competition', loadChildren: () => import('./pages/competition/competition.module').then(m => m.CompetitionModule)},
  {path: 'faqs', loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsModule)},
  {path: 'schedules', loadChildren: () => import('./pages/schedules/schedules.module').then(m => m.SchedulesModule)},
  {path: 'schedules-list', loadChildren: () => import('./pages/schedules-list/schedules-list.module').then(m => m.SchedulesListModule)},
  {path: 'player', loadChildren: () => import('./pages/player/player.module').then(m => m.PlayerModule)},
  {path: 'marketing', loadChildren: () => import('./pages/marketing/marketing.module').then(m => m.MarketingModule)},
  {path: 'packages', loadChildren: () => import('./pages/packages/packages.module').then(m => m.PackagesModule)},
  {path: 'terms-of-use', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: 'privacy-policy', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: 'company-details', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
