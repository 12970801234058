<div (click)="toggleInPlayerUserMenu($event)"
     *ngIf="userData"
     class="user-menu-dropdown">
  HI, {{userData.account.full_name.split(' ')[0]}}
</div>

<span (scroll)="scrollHandler($event)"
      class="inplayer-menu"
      id="inplayer-menu-content">
</span>

<a (click)="inPlayerService.paywallLogin($event)"
   *ngIf="!userData"
   [commonText]="'button.login'"
   class="btn btn-outline-primary">Login</a>

<div [id]="'inplayer-'+initialPaywallAssetId" style="display:none"></div>
