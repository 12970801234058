import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostBinding,
  Inject, OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
  Input, Output, EventEmitter, ViewChild, ElementRef,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {FormGroup, FormControl} from '@angular/forms';
import {filter} from 'rxjs/operators';
import { SubSink } from 'subsink';

import {HeaderData} from '../../types/wordpress/layout/header.type';
import {WINDOW} from '../../providers/window-provider';
import {InPlayerService} from '../../../modules/in-player/in-player.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  @Input() isEmbed: boolean;


  @Output()
  onLogInClick: EventEmitter<any>;

  @Output()
  onLogOutClick: EventEmitter<any>;

  @Output()
  onSignUpClick: EventEmitter<any>;

  @HostBinding('id') idAttribute;
  form: FormGroup = new FormGroup({
    search: new FormControl(),
  });

  public menuOpened = false;
  public searchOpened = false;

  @ViewChild('inplayerMenu', { static: true }) inPlayerMenuElRef: ElementRef;

  private subscriptions: SubSink;

  constructor(private router: Router,
              @Inject(WINDOW) private window: Window,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef,
              private elementRef: ElementRef,
              public inPlayerService: InPlayerService) {
    this.idAttribute = 'header';
    this._headerData = null;
    this._userData = null;

    this.onLogInClick = new EventEmitter<any>();
    this.onLogOutClick = new EventEmitter<any>();
    this.onSignUpClick = new EventEmitter<any>();

    this.subscriptions = new SubSink();
  }

  private _userData: { [key: string]: any };

  get userData() {
    return this._userData;
  }

  private _headerData: HeaderData;

  get headerData(): HeaderData {
    return this._headerData;
  }

  @Input() set headerData(newHeaderData: HeaderData) {
    this._headerData = newHeaderData;
  }


  ngOnInit(): void {
    this.subscriptions.add(this.inPlayerService.userState
        .subscribe(res => {
          if (res) {
            this._userData = res;
          }
          this.cd.markForCheck();
        })
    );

    this.subscriptions.add(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      })
    );

    this.window.document.addEventListener('click', this.closeMenuOnDocumentClick.bind(this));
    this.window.document.addEventListener('touchstart', this.closeMenuOnDocumentClick.bind(this));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openMenu() {
    this.menuOpened = true;
    this.scrollToTop();
    setTimeout(() => {
      if (this.menuOpened) {
        this.renderer.addClass(this.window.document.body, 'mobile-overlay-opened');
      }
    }, 700);
  }

  closeMenu() {
    this.menuOpened = false;
    this.renderer.removeClass(this.window.document.body, 'mobile-overlay-opened');
    // trigger refresh html...
    if (this.cd) {
      this.cd.markForCheck();
    }
  }


  openSearch(inputElement: HTMLInputElement) {
    this.searchOpened = true;
    this.scrollToTop();
    // waiting the end of CSS animation
    setTimeout(() => {
      this.renderer.addClass(this.window.document.body, 'mobile-overlay-opened');
      inputElement.focus();
    }, 700);
  }

  closeSearch() {
    this.searchOpened = false;
    this.renderer.removeClass(this.window.document.body, 'mobile-overlay-opened');
  }

  submitSearch(event: Event, inputElement: HTMLInputElement) {
    event.preventDefault();
    const search = this.form.get('search').value;
    this.closeSearch();
    if (search !== '') {
      this.form.setValue({
        search: '',
      });

      inputElement.blur();

      this.router.navigate(['/archive/all'], {queryParams: {search, preserve: true}});
    }
  }

  private scrollToTop(): void {
    this.window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }

  private closeMenuOnDocumentClick(event: Event): void {
    const isDescendant = (this.elementRef.nativeElement as Element).contains(event.target as Element);

    if (!isDescendant) {
      if (this.menuOpened) {
        this.closeMenu();
      }
      if (this.searchOpened) {
        this.closeSearch();
      }
    }
  }
}
