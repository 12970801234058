import {Directive, ElementRef, Input, OnInit, OnDestroy} from '@angular/core';

import {CommonTextService} from '../services/common-text.service';
import {SubSink} from 'subsink';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[commonText]'
})
export class CommonTextDirective implements OnDestroy {
  private _key: string;
  private subscriptions: SubSink;

  constructor(private elementRef: ElementRef,
              private commonTextService: CommonTextService) {
    this.subscriptions = new SubSink();
  }

  get key(): string {
    return this._key;
  }

  @Input('commonText') set key(value: string) {
    this._key = value;

    if (value) {
      this.subscriptions.add(
        this.commonTextService.getLabelObservable(value)
          .subscribe((label: string) => {
            this.elementRef.nativeElement.innerHTML = label;
          })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
