import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ScriptTagService} from './script-tag.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ScriptTagService
  ]
})
export class ScriptTagModule { }
