import {WpCommonFields, WpLabel} from '../types/wordpress/wp-common-fields.type';
import { HeaderData } from '../types/wordpress/layout/header.type';
import { FooterData } from '../types/wordpress/layout/footer.type';

export class WordpressUtils {
  public static extractTranslationData(commonData: WpCommonFields): WpLabel[] {
    return commonData.common_fields.labels;
  }

  public static extractHeaderData(commonData: WpCommonFields): HeaderData {
    return {
      home_icon: commonData.common_fields.home_icon
    };
  }

  public static extractFooterData(commonData: WpCommonFields): FooterData {
    const { sponsors, social_links_title, social_links, footer_links, copyright, legal_links, home_icon } = commonData.common_fields;

    return {
      sponsors,
      social_links_title,
      social_links,
      footer_links,
      copyright,
      legal_links,
      home_icon
    };
  }
}
