<header>
  <div class="logo">

    <ng-template #logo>
      <ng-container
        [ngTemplateOutletContext]="{ headerData : headerData }"
        [ngTemplateOutlet]="headerData && headerData.home_icon && headerData.home_icon.icon ? home_icon : fallback_home_icon"></ng-container>
      <ng-template #home_icon let-headerData="headerData">
        <img [alt]="'header.logotext' | commonText" [defaultImage]="defaultImage" [errorImage]="defaultImage"
             [lazyLoad]="headerData.home_icon.icon"/>
      </ng-template>
      <ng-template #fallback_home_icon>
        <div [commonText]="'header.logotext'"></div>
      </ng-template>
    </ng-template>

    <a routerLink="/home" *ngIf="!isEmbed; else logo">
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </a>
  </div>

  <div [class.opened]="menuOpened" class="mobile-overlay" id="menu-container">
    <div (click)="closeMenu()" class="close"></div>
    <nav>
      <ng-container *ngIf="!isEmbed">
        <div><a [commonText]="'menu.home'" routerLink="/home" routerLinkActive="selected">Home</a></div>
        <!-- <div><a [commonText]="'menu.24-7tv'" routerLink="/player/24-7tv" routerLinkActive="selected">24/7 TV</a></div> -->
        <div><a [commonText]="'menu.schedules'" routerLink="/schedules" routerLinkActive="selected">Schedules</a></div>
        <div>
          <a [commonText]="'menu.competitions'" routerLink="/competitions" routerLinkActive="selected">
            <span routerLink="/competition"><!-- this is to apply the class on /competition --></span>
            Competitions
          </a>
        </div>
        <div><a [commonText]="'menu.archive'" routerLink="/archive" routerLinkActive="selected">Archive</a></div>
        <div><a [commonText]="'menu.faqs'" routerLink="/faqs" routerLinkActive="selected">FAQs</a></div>
      </ng-container>

      <div *ngIf="isEmbed"><a href="/faqs" [commonText]="'menu.faqs'" target="popup" onclick="window.open('/faqs?noHeaderFooter=1','popup','width=600,height=600'); return false;">FAQs</a></div>

      <div *ngIf="!userData" class="sign-up-wrapper">
        <a (click)="inPlayerService.paywallLogin($event, true)"
           [commonText]="'button.sign-up'"
           class="btn btn-primary btn-sign-up">Sign Up</a>
      </div>
      <div *ngIf="inPlayerService && inPlayerService.paywall" app-in-player></div>
    </nav>
  </div>

  <ng-container *ngIf="!isEmbed">
    <div [class.opened]="searchOpened" class="search-container mobile-overlay">
      <div (click)="closeSearch()" class="close"></div>
      <form (ngSubmit)="submitSearch($event, headerSearchBar)" [formGroup]="form">
        <div class="search-content">
          <label [commonText]="'header.search.label'" for="header-search-bar">Search</label>
          <div class="field-container">
            <input #headerSearchBar formControlName="search" id="header-search-bar"
                   placeholder="Search..."
                   type="text"/>
            <input type="submit"/>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="!isEmbed" (click)="openSearch(headerSearchBar)" class="search-icon"></div>
  </ng-container>
  <div (click)="openMenu()" class="menu-mobile-icon"></div>
</header>
