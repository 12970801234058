import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonTextDirective } from './core/directives/common-text.directive';
import { RouteTransformerDirective } from './core/directives/route-transformer.directive';

import { CommonTextPipe } from './core/pipe/common-text.pipe';
import { SafeHtmlPipe } from './core/pipe/safe-html.pipe';
import { StripHtmlPipe } from './core/pipe/strip-html.pipe';

@NgModule({
  declarations: [
    CommonTextDirective,
    RouteTransformerDirective,
    CommonTextPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonTextDirective,
    RouteTransformerDirective,
    CommonTextPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
  ],
  providers: [
    CommonTextDirective,
  ]
})
export class SharedModule { }
