import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {InPlayerService} from '../in-player.service';
import {environment} from '../../../../environments/environment';

declare var InPlayer;

@Component({
  selector: '[app-in-player]',
  templateUrl: './in-player.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InPlayerComponent implements OnInit, AfterViewInit {
  inPlayerMenuEl: HTMLElement;
  inPlayerMenuToggler: HTMLElement;
  userData;
  initialPaywallAssetId: number;

  @HostBinding('class.inPlayerIsLogged') inPlayerIsLogged: boolean = false;

  constructor(public inPlayerService: InPlayerService,
              private elRef: ElementRef,
              private cd: ChangeDetectorRef) {
    this.initialPaywallAssetId = environment.inPlayer.initialPaywallAsset;

  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.inPlayerMenuEl) {
      const menuEl: HTMLElement = this.inPlayerMenuEl.getElementsByClassName('inplayer-menu')[0] as HTMLElement;
      const isVisible: boolean = this.inPlayerMenuEl.getElementsByClassName('inplayer-menu show').length > 0;
      if (menuEl && isVisible) {
        this.inPlayerMenuToggler.click();
      }
    }
  }

  get hasInPlayer(): boolean {
    return typeof InPlayer !== 'undefined';
  }

  inPlayerSetConfig(): void {
    InPlayer.setConfig(this.inPlayerService.inPlayerConfig.environment);
    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    
    if (this.hasInPlayer) {
      this.inPlayerSetConfig();
    }
  }

  ngOnInit() {
    this.inPlayerMenuEl = this.elRef.nativeElement;
    this.inPlayerService.userState
      .subscribe(res => {
        this.userData = res ? res : null;
        if (res) {
          this.inPlayerIsLogged = true;
        } else {
          this.inPlayerIsLogged = false;
        }
        this.cd.markForCheck();
      });

    this.inPlayerService.paywallModalWindowRef
      .subscribe(res => {
        if (res) {
          this.overrideInPlayerPaywallModal(res);
        }
      });

  }


  toggleInPlayerUserMenu(e) {
    e.preventDefault();
    this.inPlayerMenuToggler = this.inPlayerMenuEl.getElementsByClassName('inplayer-toggle-menu')[0] as HTMLElement;
    this.inPlayerMenuToggler.click();
  }

  overrideInPlayerPaywallModal(modal: HTMLElement) {
    modal.addEventListener('click', (e) => {
      switch (e.target['className']) {
        case 'termslink':
          e.preventDefault();
          window.open('/terms-of-use', '_blank');
          break;
        default:
          break;
      }
    });
  }

}
