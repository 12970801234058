import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { Subscription } from 'rxjs';

import { WordpressUtils } from './core/utils/wordpress-utils';

import { HeaderData } from './core/types/wordpress/layout/header.type';
import { FooterData } from './core/types/wordpress/layout/footer.type';
import { WordpressService } from './core/services/wordpress.service';
import { CommonTextService } from './modules/shared/core/services/common-text.service';
import { TitleService } from './modules/shared/core/services/title.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  public wpHeaderData: HeaderData;
  public wpFooterData: FooterData;

  public isEmbed: boolean;
  public noHeaderFooter: boolean;

  private commonFieldsWordpressSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: TitleService,
    private wordpressService: WordpressService,
    private commonTextService: CommonTextService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2) {
    this.wpFooterData = null;

    if (window.location.pathname === '/embed') {
      this.isEmbed = true;
    }else if(window.location.search.includes('noHeaderFooter')){

      this.noHeaderFooter = true;
    }

  }

  private static setScreenSizesOnRoot(setWhat) {
    if (setWhat !== 'w') {
      const vh = window.innerHeight;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
    if (setWhat !== 'h') {
      const bodyData = document.body.getBoundingClientRect();
      document.documentElement.style.setProperty('--vw', Math.floor(bodyData.width * 100) / 100 + 'px');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    AppComponent.setScreenSizesOnRoot('both');
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    setTimeout(() => {
      AppComponent.setScreenSizesOnRoot('both');
    }, 200);
  }

  ngOnInit(): void {
    this.commonFieldsWordpressSubscription = this.wordpressService.getCommonData()
      .subscribe(wpCommonData => {
        if (wpCommonData && typeof wpCommonData === 'object' && !Array.isArray(wpCommonData)) {
          this.wpHeaderData = WordpressUtils.extractHeaderData(wpCommonData);
          this.wpFooterData = WordpressUtils.extractFooterData(wpCommonData);
          this.commonTextService.addLabels(WordpressUtils.extractTranslationData(wpCommonData));
          AppComponent.setScreenSizesOnRoot('both');
        }
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {

        AppComponent.trackPageView(event);

        const paymentSuccess = localStorage.getItem('paymentSuccessful');
        if (paymentSuccess) {
          const msg = document.getElementsByClassName('payment-success')[0];
          msg.className = 'payment-success visible';
          setTimeout(() => {
            msg.className = 'payment-success hidden';
          }, 3000);
          localStorage.removeItem('paymentSuccessful');
        }


        this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
      }
    });
  }


  private static trackPageView(event: NavigationEnd): void {
    if ((window as any).gtag && environment.gtm) {

      (window as any).gtag('config', environment.gtm, {
        page_path: event.urlAfterRedirects
      });
    }
  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  ngOnDestroy(): void {
    this.commonFieldsWordpressSubscription.unsubscribe();
  }

}
