import { Pipe, PipeTransform } from '@angular/core';

import { CommonTextService } from '../services/common-text.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'commonText'
})
export class CommonTextPipe implements PipeTransform {
  constructor(private commonTextService: CommonTextService) {
  }

  transform(key: string): Observable<string> {
    return this.commonTextService.getLabelObservable(key);
  }
}
