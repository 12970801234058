import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InPlayerComponent} from './in-player/in-player.component';
import {ScriptTagModule} from '../script-tag/script-tag.module';
import {SharedModule} from '../shared/shared.module';
import {InPlayerConfig} from './_data/_in-player';
import {InPlayerService} from './in-player.service';
import { PackageSelectComponent } from './package-select/package-select.component';
import { CurrencyLettersToSymbolPipe } from './currency-letters-to-symbol.pipe';
import { MarketingPackageSelectComponent } from './marketing-package-select/marketing-package-select.component';

@NgModule({
  declarations: [
    InPlayerComponent,
    PackageSelectComponent,
    MarketingPackageSelectComponent,
    CurrencyLettersToSymbolPipe
  ],
  imports: [
    CommonModule,
    ScriptTagModule,
    SharedModule
  ],
  exports: [
    InPlayerComponent,
    PackageSelectComponent,
    MarketingPackageSelectComponent
  ]
})

export class InPlayerModule {
  static forRoot(inPlayerConfig: InPlayerConfig): ModuleWithProviders {
    return {
      ngModule: InPlayerModule,
      providers: [
        InPlayerService,
        {
          provide: inPlayerConfigService,
          useValue: inPlayerConfig
        }]
    };
  }
}

export const inPlayerConfigService = new InjectionToken<InPlayerConfig>('InPlayerConfig');
