<div id="sponsor-container">
  <ng-container [ngTemplateOutlet]="footerData && footerData.sponsors && (footerData.sponsors.length > 0) ? sponsors : fallback_sponsors"
                [ngTemplateOutletContext]="{ footerData : footerData }"></ng-container>
  <ng-template #sponsors let-footerData="footerData">
      <a *ngFor="let sponsor of footerData.sponsors;"
         target="_blank"
         [href]="sponsor.link">
          <img [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="sponsor.icon" [alt]="sponsor.link" />
      </a>
  </ng-template>
  <ng-template #fallback_sponsors [commonText]="'footer.nosponsors'">
  </ng-template>
</div>

<footer>
  <div class="logo">
    <a routerLink="/home">
      <ng-container [ngTemplateOutlet]="footerData && footerData.home_icon && footerData.home_icon.icon ? home_icon : fallback_home_icon"
                    [ngTemplateOutletContext]="{ footerData : footerData }"></ng-container>
      <ng-template #home_icon let-footerData="footerData">
        <img [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="footerData.home_icon.icon" [alt]="'footer.logotext' | commonText" />
      </ng-template>
      <ng-template #fallback_home_icon>
        <div [commonText]="'footer.logotext'"></div>
      </ng-template>
    </a>
  </div>
  <div class="menu menu-1">
    <ul class="ul-no-style menu-list">
      <li><a routerLink="/home" routerLinkActive="selected" [commonText]="'menu.home'">Home</a></li>
      <li><a routerLink="/schedules" routerLinkActive="selected" [commonText]="'menu.schedules'">Schedules</a></li>
    </ul>
  </div>
  <div class="menu menu-2">
    <ul class="ul-no-style menu-list">
      <li><a routerLink="/archive" routerLinkActive="selected" [commonText]="'menu.archive'">Archive</a></li>
      <li><a routerLink="/faqs" routerLinkActive="selected" [commonText]="'menu.faqs'">FAQs</a></li>
    </ul>
  </div>
  <div class="competitions">
    <ul class="ul-no-style menu-list">
      <li><a routerLink="/competitions" routerLinkActive="selected" [commonText]="'menu.competitions'">Competitions</a></li>
      <ul class="ul-no-style menu-list">
        <li *ngFor="let competition of footerCompetitions">
          <a [routerLink]="competition.link" routerLinkActive="selected" [innerText]="competition.label"></a>
        </li>
      </ul>
    </ul>
  </div>
  <div class="social-links">
    <ng-container *ngIf="footerData && footerData.social_links">
      <ul class="ul-no-style social-list">
        <li [commonText]="'footer.follow-us'">Follow Us</li>
        <li *ngFor="let link of footerData.social_links">
          <a target="_blank"
              [href]="link.link"></a>
        </li>
      </ul>
    </ng-container>
  </div>
  <div class="menu menu-3">
    <ng-container *ngIf="footerData && footerData.legal_links">
      <ul class="ul-no-style menu-list">
        <li *ngFor="let link of footerData.legal_links">
          <a  [routerLink]="link.link"
              [textContent]="link.label"></a>
        </li>
      </ul>
    </ng-container>
  </div>
  <div class="copyright">
    <div [commonText]="'footer.copyright'">© All rights reserved</div>
  </div>
</footer>
