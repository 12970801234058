import {Component, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {FooterData} from '../../types/wordpress/layout/footer.type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  private footerCompetitionsData: Array<any> = [];

  @Input() set footerData(newFooterData: FooterData) {
    this._footerData = newFooterData;
    if (newFooterData) {
      this.footerCompetitionsData = newFooterData.footer_links.filter((item: any) => {
        return (item.link && (item.link.indexOf('/competition/') >= 0));
      });
    } else {
      this.footerCompetitionsData = [];
    }
  }

  get footerData(): FooterData {
    return this._footerData;
  }

  get footerCompetitions(): Array<any> {
    return this.footerCompetitionsData;
  }

  private _footerData: FooterData;

  constructor() {
    this._footerData = null;
  }
}
