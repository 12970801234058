import {Inject, Injectable, isDevMode} from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import {WINDOW} from '../../../../core/providers/window-provider';
import {WpLabel} from '../../../../core/types/wordpress/wp-common-fields.type';

export enum LabelLanguage {
  en = 'en'
}

export enum DictionaryChange {
  languageChange = 'languageChange',
  dictionaryChange = 'dictionaryChange'
}

export interface LabelDictionary {
  [labelKey: string]: {
    subject: BehaviorSubject<string>;

    [labelLanguage: string]: { value: string };
  };
}

@Injectable({
  providedIn: 'root'
})
export class CommonTextService {
  private static readonly defaultLanguageStorageKey = 'fiba_lang';
  private static readonly defaultLanguage = LabelLanguage.en;

  private labelDictionary: LabelDictionary;
  private applicationLanguage: LabelLanguage;
  private labelLanguageSubject: BehaviorSubject<LabelLanguage>;
  private onDictionaryChangeSubject: BehaviorSubject<DictionaryChange>;

  constructor(@Inject(WINDOW) private window: Window) {
    this.applicationLanguage = this.getApplicationLanguage();
    this.labelLanguageSubject = new BehaviorSubject<LabelLanguage>(this.applicationLanguage);
    this.onDictionaryChangeSubject = new BehaviorSubject<DictionaryChange>(null);
    this.labelDictionary = {};
  }

  /**
   * Listen to dictionary changes.
   */
  public getOnDictionaryChangeObservable(): Observable<DictionaryChange> {
    return this.onDictionaryChangeSubject.asObservable();
  }

  public setCurrentLanguage(language: LabelLanguage): void {
    if (language) {
      this.applicationLanguage = language;
      this.labelLanguageSubject.next(language);

      this.setApplicationLanguage(language);
      this.updateSubjectsForLanguageChange(language);

      this.onDictionaryChangeSubject.next(DictionaryChange.languageChange);
    }
  }

  public addLabels(labels: WpLabel[]): void {
    if (labels && labels.length) {
      labels.forEach(label => {
        const { key, ...captions } = label;

        Object.keys(captions).forEach(languageKey => {
          const value = captions[languageKey];
          const language = LabelLanguage[languageKey];

          if (language) {
            this.addLabelToDictionary(key, language, value);
          }
        });
      });

      this.onDictionaryChangeSubject.next(DictionaryChange.dictionaryChange);
    }
  }

  public getLabel(key: string): string {
    if (!this.labelDictionary[key]) {
      this.addLabel(key, this.applicationLanguage, '');
      this.warnThatKeyIsMissing(key);
    }

    return this.labelDictionary[key][this.applicationLanguage].value;
  }

  public getLabelObservable(key: string): Observable<string> {
    if (!this.labelDictionary[key]) {
      this.addLabel(key, this.applicationLanguage, '');
      this.warnThatKeyIsMissing(key);
    }

    return this.labelDictionary[key].subject.asObservable();
  }

  private updateSubjectsForLanguageChange(currentLanguage: LabelLanguage): void {
    Object.values(this.labelDictionary).forEach(value => {
      if (value[currentLanguage]) {
        value.subject.next(value[currentLanguage].value);
      } else if (value[CommonTextService.defaultLanguage]) {
        value.subject.next(value[CommonTextService.defaultLanguage].value);
      }
    });
  }

  private addLabelToDictionary(key: string, language: LabelLanguage, value: string): void {
    if (this.labelDictionary[key] && this.labelDictionary[key][language]) {
      this.updateLabel(key, language, value);
    } else if (this.labelDictionary[key]) {
      this.addLanguageAndUpdate(key, language, value);
    } else {
      this.addLabel(key, language, value);
    }
  }

  private addLanguageAndUpdate(key: string, language: LabelLanguage, value: string): void {
    this.labelDictionary[key][language] = { value };

    if (this.applicationLanguage === language) {
      this.labelDictionary[key].subject.next(value);
    }
  }

  private addLabel(key: string, language: LabelLanguage, value: string): void {
    this.labelDictionary[key] = {
      ...this.labelDictionary[key],

      subject: new BehaviorSubject(value),

      [language]: {
        value
      }
    };
  }

  private updateLabel(key: string, language: LabelLanguage, value: string): void {
    this.labelDictionary[key][language].value = value;

    if (this.applicationLanguage === language) {
      this.labelDictionary[key].subject.next(value);
    }
  }

  private getApplicationLanguage(): LabelLanguage {
    if (this.window.localStorage) {
      const value = this.window.localStorage.getItem(CommonTextService.defaultLanguageStorageKey);

      if (value) {
        const language = Object.values(LabelLanguage).find(captionLanguage => captionLanguage === value );

        if (language) {
          return language;
        }
      }
    }

    return CommonTextService.defaultLanguage;
  }

  private setApplicationLanguage(value: LabelLanguage): void {
    if (this.window.localStorage) {
      this.window.localStorage.setItem(CommonTextService.defaultLanguageStorageKey, value);
    }
  }

  private warnThatKeyIsMissing(key: string) {
    if (isDevMode()) {
      setTimeout(() => {
        const label = this.labelDictionary[key];
        const value = label[this.applicationLanguage].value;
        if (!label || value === '') {
          console.warn(`${key} does not exist in CommonTextService`);
        }
      }, 5000);
    }
  }
}
