import { Inject, Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inPlayerConfigService } from './in-player.module';
import { InPlayerConfig, InPlayerKsResponse, UserState } from './_data/_in-player';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DeviceUtils } from 'amg-fe-utils';
import { Router } from '@angular/router';

declare var InplayerPaywall;

@Injectable()
export class InPlayerService {

  constructor(@Inject(inPlayerConfigService) public inPlayerConfig: InPlayerConfig,
              private http: HttpClient,
              private router: Router) {
    this.userDataReceived = false;
    this.isLoggedIn = false;
    this.userToken = '';
    this.paywall = new InplayerPaywall(this.inPlayerConfig.merchant_uuid,
      [
        {
          id: environment.inPlayer.initialPaywallAsset,
          options: {
            noPreview: true,
            noInject: true,
          },
        },
      ]);

    console.log('inPlayerConfig : ', JSON.stringify(inPlayerConfig));

    this.paywall.on('any', (e, data) => {
      //  console.log('this.paywall.on any event: ', JSON.stringify(e), JSON.stringify(data));

      const currentUrl = this.router.url;
      this.canAccessPaywallAsset.next((data && data.asset && data.asset.hasAccess));
      if (data && data.account && (data.refresh_token) && currentUrl.includes('/player')) { // data received on login only
        window.location.reload(true);
      }
      if (e && e.type === 'logout') {
        sessionStorage.clear();
        localStorage.clear();
        location.reload(true);
      }
      if (data && data.payment && data.payment.status === 'success') { // successful payment event
        localStorage.setItem('paymentSuccessful', this.router.url);
        if (currentUrl.includes('/packages')) {
          this.router.navigate(['home']);
        } else {
          window.location.reload();
        }
      }
      // if (data && data.account && data.refreshToken && data.token) { // user already logged in
      //   // window.location.reload();
      // }
      // if (data && data.account && data.refresh_token && currentUrl.includes('/packages')) { // user signed up
      //  // window.location.reload();
      // }
    });

    this.paywall.on('authenticated', (e, data) => {

      console.log('this.paywall.on authenticated event: ', JSON.stringify(e), JSON.stringify(data));

      InPlayerService.notifyAnalytics('authenticated', 'Successful authenticated');


      if (data && data.account) {
        this.setUserState(data);
      }
    });

    this.paywall.on('register', function (e, data) {
      console.log('this.paywall.on register event: ', JSON.stringify(e), JSON.stringify(data));

      InPlayerService.notifyAnalytics('register', 'Successful registered');

    });

    this.paywall.on('payment', (e, data) => {

      console.log('this.paywall.on payment event: ', JSON.stringify(e), JSON.stringify(data));

      if ((window as any).dataLayer && (window as any).gtag && environment.gtm) {
        /*(window as any).gtag('event', 'purchase', {
          transaction_id: data.payment.transaction,
          affiliation: data.payment.previewTitle,
          value: data.payment.amount || data.payment.chargedAmount,
          currency: data.payment.currency_iso || data.payment.currencyIso,
          shipping: 0,
          items: [
            {id: data.payment.item_id, name: data.payment.previewTitle},
          ],
        });*/


        (window as any).gtag('event', 'conversion', {
          'send_to': `${environment.gtm}/s_G2CKOGy4ADEJLTqZMo`,
          'value': data.payment.amount || data.payment.chargedAmount,
          'currency': data.payment.currency_iso || data.payment.currencyIso,
          'transaction_id': data.payment.transaction,
        });
      }


      if ((window as any).fbq) {
        (window as any).fbq('track', 'Purchase', {
          // transaction_id: data.payment.transaction,
          // affiliation: data.payment.previewTitle,
          value: data.payment.amount || data.payment.chargedAmount,
          currency: data.payment.currency_iso || data.payment.currencyIso,
          // shipping: 0,
          // items: [
          //   { id: data.payment.item_id, name: data.payment.previewTitle }
          // ]
        });
      }

      //  InPlayerService.notifyAnalytics('buy', data.payment ? data.payment.description : null, data.payment ? {value: `${data.payment.amount || data.payment.chargedAmount} ${data.payment.currency_iso || data.payment.currencyIso}`} : null);
    });

    this.paywall.on('logout', function (e, data) {

      console.log('this.paywall.on logout event: ', JSON.stringify(e), JSON.stringify(data));

      InPlayerService.notifyAnalytics('logout', 'Successful logout');

    });

    this.paywallInstance = this.paywall;
  }

  get paywallModalWindowRef(): Observable<any> {
    return this._paywallModalWindowRef.asObservable();
  }

  set paywallModalWindowRef(el) {
    if (el) {
      this.paywallModalRefLocal = el;
      this._paywallModalWindowRef.next(el);
    }
  }

  get paywallInstance(): Observable<any> {
    return this._paywallInstance.asObservable();
  }

  set paywallInstance(paywall) {
    if (paywall) {
      this._paywallInstance.next(paywall);
    }
  }

  get userState(): Observable<any> {
    return this._userState.asObservable();
  }

  paywall: {
    [key: string]: any;
    showPaywall: any;
  } | any;
  isLoggedIn: boolean;
  public paywallModalRefLocal: any;
  private canAccessPaywallAsset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userDataReceived: boolean;
  private userToken: string;

  private _paywallModalWindowRef: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _paywallInstance: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _userState: BehaviorSubject<UserState> = new BehaviorSubject<UserState>(null);

  private static notifyAnalytics(eventAction: string, eventLabel?: string, data?: Object) {
    if ((window as any).dataLayer && (window as any).gtag && environment.gtm) {


      console.log('event', eventAction);

      // Google GTM
      (window as any).gtag('event', eventAction, {
        event_category: 'Paywall',
        event_label: eventLabel,
        ...data,
      });
    }

    if ((window as any).fbq) {
      // Facebook Pixel
      (window as any).fbq('trackCustom', 'Paywall', {
        event_action: eventAction,
        event_label: eventLabel,
        ...data,
      });


      console.log('trackCustom', {
        event_type: 'Paywall',
        event_label: eventLabel,
        ...data,
      });
    }
  }


  getCanAccessPaywallAssetAsObservable(): Observable<boolean> {
    return this.canAccessPaywallAsset.asObservable();
  }

  paywallLogin(e, registerFirst?) {
    e.preventDefault();
    if (registerFirst) {
      this.paywall.showPaywall({
        asset: {
          assetId: environment.inPlayer.initialPaywallAsset,
        },
        registerFirst: true,
      });
    } else {
      this.paywall.showPaywall();
    }
    this.paywallModalWindowRef = document.getElementsByClassName('ReactModalPortal')[0] as any;
  }

  getKS(entryId: string): Observable<InPlayerKsResponse> { // inPlayer assetId
    return this.getKSByEntryId(entryId)
      .pipe(
        map(response => {
          if (response && response.item && response.item.content) {
            const ks = JSON.parse(response.item.content).token;
            if (ks) {
              return {ks};
            }
          }
        }),
        catchError((err, caught) => {
          if (isDevMode()) {
            console.log(err, caught);
          }

          if (err && typeof err.status === 'number') {
            if (err.status === 401) {
              return of({
                authorizationError: true,
              });
            } else if (err.status === 402 || err.status === 403) {
              return of({
                subscriptionError: true,
              });
            } else if (err.status === 404) {
              if (isDevMode()) {
                console.warn('Asset is not added in InPlayer.');
              }

              return of({
                assetNotFound: true,
              });
            }
          }

          return of({
            genericError: true,
          });
        }),
      );
  }

  public setUserState(state: UserState) {
    if (state) {
      const token = state.token || state.access_token;
      if (this.userToken !== token) {
        this.userToken = token;
        this._userState.next(state);
        this.isLoggedIn = true;
        localStorage.setItem('inPlayerAccount', JSON.stringify(state));
      }
    } else {
      this.isLoggedIn = false;
      this._userState.next(null);
      this.userToken = '';
      localStorage.removeItem('inPlayerAccount');
    }
  }

  private getKSByEntryId(entry_id: string): Observable<any> {
    const ksRequest: any = this.inPlayerRequest(`items/${environment.inPlayer.initialPaywallAsset}/access?entry_id=${entry_id}`, DeviceUtils.userAgent.IE ? {xzy: Date.now().toString()} : {});
    return !!entry_id
      ? ksRequest
      : of(null);
  }

  private inPlayerRequest(path: string, params?: { [key: string]: string }): Observable<any> {
    return this.http.get(`${this.inPlayerConfig.api}/${path}`, {
      headers: {
        Authorization: `Bearer ${this.userToken}`,
      },
      params,
    });
  }

}
