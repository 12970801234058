import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { WpCommonFields } from '../types/wordpress/wp-common-fields.type';

@Injectable({
  providedIn: 'root',
})
export class WordpressService {
  private cache: { [url: string]: ReplaySubject<any> };

  private static generatePageUrl(path: string): string {
    return `${environment.wordpress.baseUrl}${path}`;
  }

  private static getPageUrl(key: string): string {
    return `${environment.wordpress.baseUrl}${environment.wordpress.pages[key]}`;
  }

  private static getCommonDataUrl(): string {
    return `${environment.wordpress.baseUrl}${environment.wordpress.endpoints.common}`;
  }

  private static getBoxsetUrl(): string {
    return `${environment.wordpress.baseUrl}${environment.wordpress.endpoints.boxset}`;
  }

  constructor(private http: HttpClient) {
    this.cache = {};
  }

  private static getCompetitionApiUrl(): string {
    return `${environment.wordpress.baseUrl}${environment.wordpress.endpoints.competition}`;
  }


  /**
   * Grabs the data from the memory cache if available, else it will trigger a call and retrieves the data.
   * @param pagePath WP page path
   */
  public getPageData<D>(pagePath: string): Observable<D> {
    return this.getData<D>(WordpressService.generatePageUrl(pagePath));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCommonData(): Observable<WpCommonFields> {
    return this.getData<any>(WordpressService.getCommonDataUrl());
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getBoxset(): Observable<any> {
    return this.getData<any>(WordpressService.getBoxsetUrl());
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getHomePage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('home'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getTv247Page(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('tv247'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getEmbedPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('embed'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getVideoPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('video'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getSchedulesPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('schedules'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getSchedulesListPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('schedulesList'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCompetitionsPage(): Observable<{ [t: string]: any }> {
    return this.getData<{ [t: string]: any }>(WordpressService.getPageUrl('competitions'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getArchivePage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('archive'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getBoxsetsPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('boxsets'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getPackagesPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('packages'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getMarketingPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('marketing'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getFaqPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('faq'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getTermsPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('terms'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getPrivacyPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('privacy'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCompanyPage(): Observable<any> {
    return this.getData<any>(WordpressService.getPageUrl('company'));
  }

  private getData<T>(url: string): Observable<T> {
    if (!this.cache[url]) {
      this.cache[url] = new ReplaySubject<T>(null);

      this.http.get(url).subscribe(value => {
        this.cache[url].next(value);
      }, error => {
        this.cache[url].next([]);
      });
    }

    return this.cache[url].asObservable();
  }

  public getCompetitionList(): Observable<any> {
    return this.getData<any>(WordpressService.getCompetitionApiUrl());
  }
}
