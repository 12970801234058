import { Injectable, isDevMode } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { CommonTextService } from './common-text.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private static siteTitle: string = 'LiveBasketball.tv';
  private static titleSeparator: string = ' | ';

  private subscriptions: SubSink;
  private labelSubscription: Subscription;

  private currentTitle: string;

  constructor(
    private router: Router,
    private title: Title,
    private commonTextService: CommonTextService
  ) {
    this.subscriptions = new SubSink();
    this.currentTitle = '';

    const pageMaps = new Map<string, string>([
      ['archive', 'menu.archive'],
      ['browse', 'menu.browse'],
      ['faqs', 'menu.faqs'],
      ['home', 'menu.home'],
      ['packages', 'menu.packages'],
      ['schedules', 'menu.schedules'],
      ['boxsets', 'menu.boxsets'],
      ['competitions', 'menu.competitions'],
    ]);


    this.subscriptions.add(this.router.events
      .pipe(filter<NavigationEnd>((event: Event) => event instanceof NavigationStart))
      .subscribe((navigationStart: NavigationStart) => {
        if (this.labelSubscription) {
          this.labelSubscription.unsubscribe();
        }
        const titleKey = pageMaps.get(navigationStart.url.split('/')[1]);
        if (titleKey) {
          this.labelSubscription = this.commonTextService.getLabelObservable(titleKey).subscribe((newTitle: string) => {
            this.changeTitle(newTitle);
          });
        } else {
          if (navigationStart.url.startsWith('/competition/')) {
            this.changeTitle('Competition');
          } else if (navigationStart.url.startsWith('/player/live/')) {
            this.changeTitle('Live');
          } else if (navigationStart.url.startsWith('/player/vod/')) {
            this.changeTitle('Video');
          } else {
            if (isDevMode()) {
              console.warn(`TitleService: route ${navigationStart.url} not found`);
            }
            this.title.setTitle(TitleService.siteTitle);
          }
        }
      })
    );
  }

  private changeTitle(newTitle: string, newSubtitle?: string) {
    this.currentTitle = newTitle;

    let titleArray: Array<string>;
    if (newSubtitle) {
      titleArray = [
        newTitle,
        newSubtitle,
        TitleService.siteTitle,
      ];
    } else {
      titleArray = [
        newTitle,
        TitleService.siteTitle,
      ];
    }

    this.title.setTitle(titleArray.join(TitleService.titleSeparator));
  }

  public setTitle(newTitle: string) {
    this.changeTitle(newTitle);
  }

  public setSubtitle(newSubtitle: string) {
    this.changeTitle(this.currentTitle, newSubtitle);
  }

}
