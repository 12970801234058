import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WINDOW_PROVIDERS} from './core/providers/window-provider';
import {intersectionObserverPreset, LazyLoadImageModule} from 'ng-lazyload-image';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './modules/shared/shared.module';
import {HeaderComponent} from './core/components/header/header.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {CookieNotificationComponent} from './core/components/cookie-notification/cookie-notification.component';
import {InPlayerModule} from './modules/in-player/in-player.module';
import {CloudMatrixModule} from '@streamamg/cloud-matrix-lib';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookieNotificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
    SharedModule,
    InPlayerModule.forRoot(environment.inPlayer),
    CloudMatrixModule
  ],
  providers: [
    WINDOW_PROVIDERS
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
